.GbStdTable .ag-theme-balham .ag-header {
    background: #5e9cd3;
    color: #ffffff;
}

.GbStdTable .ag-theme-balham .ag-ltr .ag-cell {
    border-right: 1px dotted #ccc; /* #9bc2e6; */
}

/*************************
    LiST Online Settings 
/************************/

.GbStdTable.LiSTOnlineTable .ag-root {
    font-family: "lato";
}

.GbStdTable.LiSTOnlineTable .ag-header-cell-label .ag-header-cell-text,
.GbStdTable.LiSTOnlineTable .ag-theme-balham,
.GbStdTable.LiSTOnlineTable .ag-theme-balham .ag-header-group-cell-label {
    color: #000000;
    font-weight: bold;
    font-size: 12px;
    font-family: "lato";
}

/* Borders */
.GbStdTable.LiSTOnlineTable .ag-theme-balham .ag-header-cell,
.GbStdTable.LiSTOnlineTable .ag-theme-balham .ag-ltr .ag-cell,
.GbStdTable.LiSTOnlineTable .ag-theme-balham .ag-ltr .ag-row.ag-cell-last-left-pinned,
.GbStdTable.LiSTOnlineTable .ag-theme-balham .ag-ltr .ag-cell:not(.ag-cell-focus).ag-cell-last-left-pinned,
.GbStdTable.LiSTOnlineTable .ag-theme-balham .ag-pinned-left-header {
    /* Cause of Broken #39 */
    /*border-right: 1px dotted #ccc;*/
}

.GbStdTable.LiSTOnlineTable .ag-theme-balham .ag-root {
    border-right: 1px solid #ccc;
}

.GbStdTable.LiSTOnlineTable .ag-theme-balham .ag-header-row .ag-header-cell:last-child {
    border-right: none;
}

.GbStdTable.LiSTOnlineTable .ag-theme-balham .ag-ltr .ag-has-focus .ag-cell-focus.ag-cell-range-single-cell {
    /* Cause of wobbling column */
    /*border: 1px solid #333;*/
}

/* .GbStdTable.LiSTOnlineTable .ag-theme-balham .ag-ltr .ag-cell,
.GbStdTable.LiSTOnlineTable .ag-theme-balham .ag-ltr .ag-cell:not(.ag-cell-focus).ag-cell-last-left-pinned,
.GbStdTable.LiSTOnlineTable .ag-theme-balham .ag-row {
    border: none;
} */

/* Borders */
.GbStdTable.LiSTOnlineTable .ag-theme-balham .ag-root,
.GbStdTable.LiSTOnlineTable .ag-theme-balham .ag-row,
.GbStdTable.LiSTOnlineTable .ag-theme-balham .ag-header,
.GbStdTable.LiSTOnlineTable .ag-theme-balham .ag-header-cell,
.GbStdTable.LiSTOnlineTable .ag-theme-balham .ag-header-group-cell {
    border-color: #ccc;
}

/* Rounded corners */
/* .GbStdTable.LiSTOnlineTable .ag-root-wrapper {
  border-radius: 7px;
} */

/* Header color */
.GbStdTable.LiSTOnlineTable .ag-theme-balham .ag-header {
    background: #9cc025;
}

/* Band color */
.GbStdTable.LiSTOnlineTable .ag-theme-balham .ag-row-odd {
    background-color: #f0f6df; /* #deebf7;*/
}

/* Merged row color???  Seems to only work for when there are "No Rows To Show" */
.GbStdTable.LiSTOnlineTable .ag-theme-balham .ag-center-cols-clipper,
.GbStdTable.LiSTOnlineTable .ag-theme-balham .ag-pinned-left-cols-container .subheading-row,
.GbStdTable.LiSTOnlineTable .ag-theme-balham .subheadingRow {
    background-color: #d7d7d7;
}

/*************************
          General 
/************************/

.GbStdTable .ag-cell-edit-input {
    border: none;
    text-align: right;
    font-size: inherit;
}

.GbStdTable .ag-cell-inline-editing {
    height: 100%;
    padding-right: 11px;
}

.GbStdTable .ag-cell-inline-editing .ag-input-text-wrapper {
    width: 100%;
}

/* Remove ellipses in column headers */
.GbStdTable .ag-header-cell-label .ag-header-cell-text {
    text-overflow: clip;
}

.GbStdTable .ag-pinned-left-cols-container .ag-row {
    z-index: 1;
}

.GbStdTable .ag-root-wrapper > :not(.ag-root) {
    border-right: unset !important;
    border-bottom: unset !important;
}

.GbStdTable .ag-theme-balham,
.GbStdTable .ag-theme-balham .ag-header {
    font-family: Arial, sans-serif;
    font-size: 12px;
}

.GbStdTable .ag-theme-balham .ag-cell-focus {
    border-right: 1px solid darkgreen;
}

.GbStdTable .ag-theme-balham .ag-input-text-wrapper input[type="text"] {
    border-style: none;
}

.GbStdTable .ag-theme-balham .ag-row-even {
    background-color: white;
}

.GbStdTable .ag-theme-balham .ag-row-odd {
    background-color: #deebf7; /* #f0f6df */
}

.GbStdTable .ag-theme-balham .ag-center-cols-clipper,
.GbStdTable .ag-theme-balham .ag-pinned-left-cols-container .subheading-row {
    background-color: #cccccc;
}

.GbStdTable .ag-theme-balham .ag-center-cols-container .ag-row .ag-cell:not(.ag-cell-focus):last-child,
.GbStdTable .ag-theme-balham .ag-root:not(.ag-has-focus) .ag-center-cols-container .ag-row .ag-cell:last-child {
    border-right: 1px transparent solid;
}

.GbStdTable
    .ag-theme-balham
    .ag-root:not(.ag-has-focus)
    .ag-center-cols-container
    .ag-row
    .ag-cell:not(.ag-cell-focus):last-child {
    border-right: 1px transparent solid;
}

.GbStdTable .ag-theme-balham .ag-row {
    border-width: 0 0 0;
}

/* Change column separator colours */

.GbStdTable.LiSTOnlineTable .ag-theme-balham .ag-header-row .ag-header-cell:last-child,
.GbStdTable.LiSTOnlineTable .ag-theme-balham .ag-header-row .ag-header-cell {
    border-right: 1px solid #ccc;
}

.GbStdTable.LiSTOnlineTable .ag-theme-balham .ag-header-container .ag-header-row:last-child .ag-header-cell:last-child {
    border-right: none;
}

.GbStdTable.LiSTOnlineTable .ag-theme-balham .ag-ltr .ag-cell.ag-cell-last-left-pinned,
.GbStdTable.LiSTOnlineTable .ag-theme-balham .ag-ltr .ag-root:not(.ag-has-focus) .ag-cell.ag-cell-last-left-pinned,
.GbStdTable.LiSTOnlineTable .ag-theme-balham .ag-ltr .ag-cell {
    border-right: 1px dotted #ccc;
}

.GbStdTable.LiSTOnlineTable .ag-theme-balham .ag-ltr .ag-has-focus .ag-cell-range-selected.ag-cell-range-single-cell,
.GbStdTable.LiSTOnlineTable .ag-theme-balham .ag-ltr .ag-cell-range-selected:not(.ag-cell-range-single-cell),
.GbStdTable.LiSTOnlineTable
    .ag-theme-balham
    .ag-center-cols-container
    .ag-row
    .ag-cell.ag-cell-range-selected:last-child,
.GbStdTable.LiSTOnlineTable
    .ag-theme-balham
    :not(.ag-has-focus)
    .ag-center-cols-container
    .ag-row
    .ag-cell.ag-cell-range-selected:last-child {
    border-right: 1px #ccc solid;
}

.GbStdTable .ag-theme-balham .subheading-row {
    background-color: transparent;
}

.GbStdTable .commented-cell:after {
    position: absolute;
    right: 0;
    top: 0;
    width: 0;
    height: 0;
    content: "";
    border-style: solid;
    border-width: 0 8px 8px 0;
    border-color: transparent rgba(0, 0, 0, 0.25) transparent transparent;
}

.GbStdTable .commented-header-cell:after {
    top: -8px;
}

.GbStdTable .header-center .ag-header-cell-label {
    justify-content: center;
}

.GbStdTable .header-left .ag-header-cell-label {
    justify-content: flex-start;
}

.GbStdTable .header-right .ag-header-cell-label {
    justify-content: flex-end;
}

.GbStdTable .ag-row:not(.subheading-row) .lock-pinned {
    /* background-color: rgba(238, 238, 238, 0.5); */
    background-color: "#FFFFFF";
}

.GbStdTable .rounded .ag-root {
    border-radius: 8px !important;
}

.GbStdTable .subheading-row .ag-cell {
    overflow: visible;
    border-right: none !important;
}

.ag-theme-balham .ag-menu {
    z-index: 66666;
}

.ag-theme-balham .ag-paging-panel {
    border-top: 1px solid #bdc3c7;
    color: rgba(0, 0, 0, 0.54);
    height: 32px;
    padding: 0;
}

.ag-theme-balham .ag-paging-button {
    color: #1293ac !important;
    background: white;
    border: 1px solid #1293ac;
    cursor: pointer;
    border-radius: 4px;
    padding: 2px 12px !important;
    line-height: 1;
}

.ag-theme-balham .ag-paging-button:hover {
    background: #135475;
    color: white !important;
}

.ag-theme-balham .ag-header-cell-label .ag-header-cell-text {
    white-space: normal;
}

/* .ag-theme-balham .ag-row {
    border-style: solid;
    border-color: #e69b9b;
    border-width: 0;
} */

.GbStdTable:not(.empty) .ag-body-viewport.ag-layout-auto-height .ag-center-cols-clipper,
.GbStdTable:not(.empty) .ag-body-viewport.ag-layout-auto-height .ag-center-cols-container {
    min-height: 0;
}

.ag-theme-balham {
    background-color: transparent;
}

/* Vertical alignment */

.GbStdTable .cell-alignment-top {
    display: flex;
    align-items: flex-start !important;
}

.GbStdTable .cell-alignment-bottom {
    display: flex;
    align-items: flex-end !important;
}

.GbStdTable .ag-theme-balham .ag-cell.ag-cell-inline-editing {
    padding-left: 0 !important;
}

.GbStdTable .ag-theme-balham .ag-cell-range-selected {
    background-color: #d2e5f6 !important;
}

/* .GbStdTable .ag-theme-balham .ag-header-row {
    height: 31px !important;
} */

/* CSS Hack: Try to make it so that the topleft corner of the tables don't overlap the row below. */
.ag-header-cell {
    height: 98%;
}

/**************************************************************************************************/
/*                                     Result table styles                                        */
/**************************************************************************************************/

.result-table .ag-cell-focus, .ag-cell {
    border: none !important;
}


