/* ======================================================== */
/*                                                          */
/*                         Common                           */
/*                                                          */
/* ======================================================== */

html, body, #root {
  box-sizing: border-box;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: Lato, san-serif, serif;
}

* {
  box-sizing: border-box;
  font-family: "Lato";
}

td:first-of-type, th:first-of-type {
  width:25px;
  margin:0;
  padding:0;
  padding-left:5px;
}

td:nth-of-type(2), th:nth-of-type(2) {
  margin-left:0;
  padding-left:0;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #FFFFFF;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* ======================================================== */
/*                                                          */
/*                         AG-Grid                          */
/*                                                          */
/* ======================================================== */

.ag-react-container input[type="checkbox"]:checked, .ag-theme-material .ag-icon-checkbox-checked {
  /* This is the target for checkboxes in agGrid */
}

/* ======================================================== */
/*                                                          */
/*                       Stepper                            */
/*                                                          */
/* ======================================================== */

.stepLabel {
  font-size: 1.4rem ! important;
}